import React, { useMemo, useState } from "react"

import { useLocation } from "@reach/router"
import algoliasearch from "algoliasearch/lite"
import Head from "decentraland-gatsby/dist/components/Head/Head"
import NotFound from "decentraland-gatsby/dist/components/Layout/NotFound"
import SubTitle from "decentraland-gatsby/dist/components/Text/SubTitle"
import useAsyncMemo from "decentraland-gatsby/dist/hooks/useAsyncMemo"
import useFormatMessage from "decentraland-gatsby/dist/hooks/useFormatMessage"
import env from "decentraland-gatsby/dist/utils/env"
import { Button } from "decentraland-ui/dist/components/Button/Button"
import { Container } from "decentraland-ui/dist/components/Container/Container"

import {
  AlgoliaSearch,
  SearchMatches,
  SearchResult,
} from "../../../components/Blog/Search/Search"
import BlogNavigation from "../../../components/Layout/BlogNavigation"
import Layout from "../../../components/Layout/Layout"
import { useBlogContentfulQuery } from "../../../hooks/useBlogContentfulQuery"
import { searchFormatWithPost } from "../../../modules/formats"

import "./index.css"

const searchClient = algoliasearch(
  env("ALGOLIA_APP_KEY")!,
  env("ALGOLIA_READY_KEY")!
)

const blogIndex = searchClient.initIndex(env("ALGOLIA_BLOG_INDEX")!)

const POST_PER_PAGE = 10

export default function SearchPage(props: any) {
  const l = useFormatMessage()

  const location = useLocation()

  const [fetchOptions, setFetchOptions] = useState({
    page: 0,
    hitsPerPage: POST_PER_PAGE,
  })

  const [searchResult, setSearchResult] = useState<
    Omit<SearchMatches, "selected" | "onMouseEnter">[]
  >([])

  const [hasLoadMore, setHasLoadMore] = useState(true)

  const query = useMemo(
    () => new URLSearchParams(location.search).get("q"),
    [location.search]
  )

  const { getBlogCategories, getBlogPost } = useBlogContentfulQuery()

  const [categories] = useMemo(getBlogCategories, [])

  const [, blogpostsState] = useAsyncMemo(
    async () => {
      if (query) {
        const algoliaResponse = await blogIndex.search<AlgoliaSearch>(
          query,
          fetchOptions
        )
        const responseFormated = searchFormatWithPost(
          algoliaResponse,
          getBlogPost
        )

        setSearchResult((prev) => {
          let newBlogPost: SearchMatches[] = []
          if (fetchOptions.page !== 0) {
            newBlogPost = [...prev]
          }
          newBlogPost = [...newBlogPost, ...responseFormated]

          if (fetchOptions.page >= algoliaResponse.nbPages - 1) {
            setHasLoadMore(false)
          }
          return newBlogPost
        })
      } else {
        setSearchResult([])
      }
    },
    [query, categories, fetchOptions],
    {
      callWithTruthyDeps: true,
    }
  )

  const loading = !blogpostsState.loaded || blogpostsState.loading

  return (
    <Layout
      {...props}
      isFullscreen
      isOverlay
      hideFooter={false}
      className="layout__blog blogpost-search"
    >
      <Head
        title={`${l("page.blog.search.search_results_for")} "{query}"` || ""}
      />
      <BlogNavigation active={props.category} />

      <Container>
        <SubTitle className="blogpost-search__title">
          {l("page.blog.search.search_results_for")} <span>"{query}"</span>
        </SubTitle>

        {!loading && searchResult && searchResult.length === 0 && (
          <div className="blog-post__empty-container">
            <NotFound
              title={l("page.blog.nothing_to_show")}
              description={l("page.blog.authors.empty_list")}
            />
          </div>
        )}
        {blogpostsState.loaded && searchResult && searchResult.length > 0 && (
          <div className="blogpost-search__wrapper">
            {searchResult.map((result, key) => (
              <SearchResult matches={result} key={key} />
            ))}
          </div>
        )}
        {loading && (
          <div className="blogpost-search__wrapper">
            {Array.from(Array(5), (_, key) => (
              <SearchResult key={key} loading />
            ))}
          </div>
        )}
        {hasLoadMore && searchResult.length !== 0 && (
          <div className="blog-post__load-more">
            <Button
              onClick={() => {
                setFetchOptions((prev) => ({
                  hitsPerPage: POST_PER_PAGE,
                  page: prev.page + 1,
                }))
                setTimeout(
                  () =>
                    window.scrollBy({ top: 500, left: 0, behavior: "smooth" }),
                  0
                )
              }}
            >
              {l("page.blog.load_more")}
            </Button>
          </div>
        )}
      </Container>
    </Layout>
  )
}
